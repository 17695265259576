import React, { useEffect, useState } from "react";
import {
  Categories,
  DiscountAvailable,
  Recipes,
  SelectedOulet,
  StoreData,
  checkDiscountOnSelectedItem,
  getShopDataFromStrg,
  imagePlaceHolder,
  isProductAvailable,
  validateDeliveryPickupPauseOrder,
} from "../../utils";
import BlockItemPrice from "../../../../shared/components/BlockItemPrice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
interface Props {
  categories: Categories[];
  isPauseOrder: boolean;
  isGridView: boolean;
  setIsPauseOrder: (value: boolean) => void;
  useData: StoreData | undefined;
  checkFromBasketHandler: (catrgoreyItem: any) => {
    isFound: boolean;
    totalItems: number;
  };
  selectedLanguageCode: any;
  showDetailLayout: (recipe: any) => void;
  discountAvailable: DiscountAvailable;
  selectedOutlet?: SelectedOulet;
}
const BlockDelivery: React.FC<Props> = (props: Props) => {
  const {
    isPauseOrder,
    categories,
    isGridView,
    checkFromBasketHandler,
    selectedLanguageCode,
    setIsPauseOrder,
    useData,
    selectedOutlet,
    showDetailLayout,
    discountAvailable,
  } = props;
  const { t } = useTranslation();
  const { bussinessName, itemId } = useParams();
  const outletInfo = getShopDataFromStrg("/" + bussinessName!);
  //@ts-ignore
  var disCountObj = useData[discountAvailable.discountType];
  var isSelectedItems =
    checkDiscountOnSelectedItem(disCountObj) === "selected_items";
  var selectedItemsDiscounts =
    disCountObj !== null && isSelectedItems && disCountObj?.discount_item;
  // JSON.parse(disCountObj?.discount_item);

  const onPressHandler = (recipe: Recipes, isAvailable: boolean) => {
    if (isPauseOrder) {
      return;
    }
    if (isAvailable) {
      showDetailLayout(recipe);
    }
  };
  useEffect(() => {
    if (
      selectedOutlet &&
      Object.keys(selectedOutlet).length > 0 &&
      validateDeliveryPickupPauseOrder(outletInfo.orderType, selectedOutlet)
    ) {
      setIsPauseOrder(true);
      // console.log(selectedOutlet);
    }
  }, [onPressHandler]);
  const checkDiscountOnSelectedItemHandler = (itemId: string) => {
    var isSelectedItem = false;

    if (
      disCountObj !== null &&
      discountAvailable.available &&
      checkDiscountOnSelectedItem(disCountObj) === "selected_items"
    ) {
      // console.log(JSON.parse(disCountObj?.discount_item));

      selectedItemsDiscounts.forEach((element: any) => {
        if (element === itemId) {
          isSelectedItem = true;
        }
      });
    }
    /**
    if (
      discountAvailable.available &&
      checkDiscountOnSelectedItem(disCountObj) === "all_items"
    ) {
      isSelectedItem = true;
    }
     */

    return isSelectedItem;
  };
  useEffect(() => {
    // checkDiscountOnSelectedItems();
  }, []);
  return (
    <div>
      {categories.map((item, index) => {
        return (
          <>
            {item.recipes && (
              <section
                key={index}
                id={`list-item${index}`}
                className="tab_default_2_hide"
              >
                <div
                  className="panel-group"
                  id="accordion1"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div className="panel panel-default">
                    <div className={`panel-heading`} role="tab" id="headingOne">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent={`#accordion${index}`}
                          href={`#collapseOpen${index}`}
                          aria-expanded="true"
                          aria-controls={`#collapseOpen${index}`}
                        >
                          {/* {selectedLanguageCode === "ar"
                                        ? item.arabic_name
                                        : item.english_name} */}
                          {item?.name[selectedLanguageCode]}
                        </a>
                      </h4>
                    </div>
                    <div
                      id={`collapseOpen${index}`}
                      className={`${
                        isGridView ? "grid_row" : "list_view_panel"
                      } panel-collapse  collapse in ${
                        isPauseOrder ? "disabled_order" : ""
                      }`}
                      // className="panel-collapse pad_bottom_20 collapse in"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      {item.recipes !== undefined &&
                        item.recipes.map((recipe, index, arr) => {
                          var isItemAvailable = isProductAvailable(
                            recipe,
                            bussinessName,
                            selectedOutlet
                          );
                          return (
                            <>
                              <div
                                key={index}
                                className={` ${
                                  checkFromBasketHandler(recipe).isFound
                                    ? "panel-body-selected"
                                    : ""
                                } ${isGridView ? "gridView" : "columnView"} ${
                                  !isItemAvailable.isAvailable
                                    ? // recipe.is_available === "No"
                                      "inactive_categories"
                                    : ""
                                }`}
                                style={{
                                  marginBottom:
                                    arr.length - 1 !== index &&
                                    isGridView &&
                                    window.innerHeight >= 1000
                                      ? "-80px"
                                      : "18px",
                                }}
                                onClick={() => {
                                  onPressHandler(
                                    recipe,
                                    isItemAvailable.isAvailable
                                  );
                                }}
                              >
                                <div className="body_warp">
                                  {isGridView && (
                                    <div
                                      style={{ marginBottom: 6 }}
                                      className={`${
                                        isGridView ? "" : "col_35_percent"
                                      } `}
                                    >
                                      <span className="glyphicon glyphicon-plus"></span>

                                      <div
                                        className={`${
                                          isGridView
                                            ? "round_cor_grid_view backkground-image-style product_img_heigth_width"
                                            : "round_cor"
                                        }`}
                                        style={{
                                          backgroundImage: `url(${
                                            recipe?.thumbnail !== null
                                              ? recipe?.thumbnail
                                              : imagePlaceHolder
                                          })`,
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div className="">
                                    {!isItemAvailable.isAvailable && (
                                      <div className="inventory_unavailable_reason">
                                        {
                                          isItemAvailable.reason
                                          // recipe?.inventory_un_available_reason[
                                          //   selectedLanguageCode
                                          // ]
                                        }
                                      </div>
                                    )}
                                    <a className="target_slide">
                                      <div
                                        className={`${
                                          !isGridView ? "col_65_percent" : ""
                                        }`}
                                        style={{
                                          marginBottom: 25,
                                          // minHeight: "50px",
                                        }}
                                      >
                                        <div className="title_acc_cell limit-two-lines">
                                          {checkFromBasketHandler(recipe)
                                            .isFound && (
                                            <div className="selected-item-number">
                                              {selectedLanguageCode == "ar" && (
                                                <span>{"x"}</span>
                                              )}
                                              <span>
                                                {
                                                  checkFromBasketHandler(recipe)
                                                    .totalItems
                                                }
                                              </span>
                                              {selectedLanguageCode == "en" && (
                                                <span>{"x"}</span>
                                              )}
                                            </div>
                                          )}

                                          <span>
                                            {" "}
                                            {recipe?.name[selectedLanguageCode]}
                                          </span>
                                        </div>
                                        <div
                                          style={{ marginTop: 3 }}
                                          className=" light_grey_text font_s  font_ar_s1 limit-two-lines"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              recipe?.description[
                                                selectedLanguageCode
                                              ],
                                          }}
                                        ></div>
                                        {recipe.is_customized === "No" && (
                                          <BlockItemPrice
                                            recipe={recipe}
                                            selectedLanguageCode={
                                              selectedLanguageCode
                                            }
                                            isSelectedItem={checkDiscountOnSelectedItemHandler(
                                              recipe.shared_unique_id
                                            )}
                                            useData={useData}
                                            isDiscountAvailabel={
                                              discountAvailable
                                            }
                                            isGridView={isGridView}
                                          />
                                        )}
                                        {recipe.is_customized === "Yes" && (
                                          <div className="customize-text-color">
                                            {t("is_customizable")}
                                          </div>
                                        )}
                                      </div>
                                    </a>
                                  </div>
                                  {!isGridView && (
                                    <div className="col_35_percent">
                                      <span className="glyphicon glyphicon-plus"></span>

                                      <div
                                        className="round_cor backkground-image-style product_img_list_heigth_width"
                                        style={{
                                          backgroundImage: `url(${
                                            recipe?.thumbnail !== null
                                              ? recipe?.thumbnail
                                              : imagePlaceHolder
                                          })`,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {arr.length - 1 !== index && !isGridView && (
                                <div className="bottom-line" />
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        );
      })}
    </div>
  );
};

export default BlockDelivery;
